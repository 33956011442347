var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: `PASSWORD_FORM_${_vm.id}` },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("user.set_password"),
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.hide },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.hide },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              on: { dismissed: _vm.dismissAlert },
              model: {
                value: _vm.errorShow,
                callback: function ($$v) {
                  _vm.errorShow = $$v
                },
                expression: "errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "password-hint" },
            [
              _vm._v(
                _vm._s(_vm.$t("complete_registration.title_detail")) + " "
              ),
              _c(
                "b-link",
                { attrs: { id: "LINK_PASS_INFO" } },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-info"] },
                  }),
                ],
                1
              ),
              _c(
                "b-popover",
                {
                  attrs: {
                    target: "LINK_PASS_INFO",
                    triggers: "click blur",
                    placement: "top",
                  },
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.passwordInfo) },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm.CompleteRegistrationFormState.LOADING === _vm.formState
            ? _c(
                "div",
                { staticClass: "text-center" },
                [_c("b-spinner", { attrs: { label: "Spinning" } })],
                1
              )
            : _vm.CompleteRegistrationFormState.HAS_VALID_TOKEN ===
              _vm.formState
            ? _c(
                "b-form",
                [
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-input-group",
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "lock"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: _vm.validate },
                                expression: "{ required: validate }",
                              },
                            ],
                            ref: "password",
                            staticClass: "last-child form-control",
                            class: { "is-invalid": _vm.showPasswordError },
                            attrs: {
                              type: "password",
                              id: "inputPassword",
                              placeholder: "Enter Password",
                              "data-vv-as": _vm.$t(
                                "complete_registration.field.password"
                              ),
                              "data-vv-name": "password",
                              "data-vv-delay": "500",
                              state: _vm.fieldValidateUtil.stateValidate(
                                false,
                                _vm.veeFields,
                                _vm.errors,
                                "password"
                              ),
                            },
                            domProps: { value: _vm.password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.password = $event.target.value
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showPasswordError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " + _vm._s(_vm.errors.first("password")) + " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    [
                      _c(
                        "b-input-group",
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "lock"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.confirmPass,
                                expression: "confirmPass",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: _vm.validate,
                                  confirmed: "password",
                                },
                                expression:
                                  "{ required: validate, confirmed: 'password' }",
                              },
                            ],
                            staticClass: "last-child form-control",
                            class: {
                              "is-invalid": _vm.showConfirmPasswordError,
                            },
                            attrs: {
                              type: "password",
                              id: "confirmPass",
                              placeholder: "Confirm Password",
                              "data-vv-as": _vm.$t(
                                "complete_registration.field.password_confirm"
                              ),
                              "data-vv-name": "confirmPass",
                              "data-vv-delay": "500",
                            },
                            domProps: { value: _vm.confirmPass },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.confirmPass = $event.target.value
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showConfirmPasswordError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " + _vm._s(_vm.errors.first("confirmPass")) + " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }