var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.modalId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.canEdit() && !_vm.readOnly
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm", variant: "success" },
                            on: { click: _vm.modalOk },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            _vm.readOnly ? "button.close" : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: "danger",
                dismissible: "",
                show: _vm.errorShow,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      class: { "mb-0": _vm.showNameError },
                      attrs: {
                        label: _vm.$t("task.field.name"),
                        "label-for": "name",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true },
                                expression: "{ required: true }",
                              },
                            ],
                            class: [_vm.readOnly ? "read-only" : ""],
                            attrs: {
                              id: "name",
                              type: "text",
                              disabled: _vm.readOnly,
                              "data-vv-as": _vm.$t("task.field.name"),
                              "data-vv-name": "group.headerName",
                              "data-vv-delay": "500",
                              state: _vm.fieldValidateUtil.stateValidate(
                                false,
                                _vm.veeFields,
                                _vm.errors,
                                "group.headerName"
                              ),
                              autofocus: "",
                              trim: "",
                            },
                            model: {
                              value: _vm.group.headerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.group, "headerName", $$v)
                              },
                              expression: "group.headerName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showNameError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.errors.first("group.headerName")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.$t("project.field.description"),
                        "label-for": "description",
                      },
                    },
                    [
                      _c("b-form-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { max: 10000 },
                            expression: "{ max: 10000 }",
                          },
                        ],
                        class: [_vm.readOnly ? "read-only" : ""],
                        attrs: {
                          id: "description",
                          disabled: _vm.readOnly,
                          placeholder: _vm.readOnly
                            ? ""
                            : _vm.$t("project.placeholder.description"),
                          "data-vv-as": _vm.$t("project.field.description"),
                          "data-vv-name": "group.description",
                          "data-vv-delay": "500",
                          "max-rows": 6,
                          trim: "",
                          rows: 3,
                        },
                        model: {
                          value: _vm.group.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.group, "description", $$v)
                          },
                          expression: "group.description",
                        },
                      }),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showDescriptionError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.errors.first("group.description")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      class: { "mb-0": _vm.showPropertyError },
                      attrs: { label: _vm.$t("task.group.field.property") },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true },
                                expression: "{ required: true }",
                              },
                            ],
                            class: [_vm.readOnly ? "read-only" : ""],
                            attrs: {
                              disabled: _vm.readOnly,
                              options: _vm.propertyOpts,
                              "data-vv-as": _vm.$t("task.group.field.property"),
                              "data-vv-name": "group.property",
                              "data-vv-delay": "500",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null, disabled: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("task.group.select_property")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.group.property,
                              callback: function ($$v) {
                                _vm.$set(_vm.group, "property", $$v)
                              },
                              expression: "group.property",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-invalid-feedback",
                        {
                          staticClass: "alert-danger form-field-alert",
                          class: { "d-block": _vm.showPropertyError },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "circle-exclamation"] },
                          }),
                          _vm._v(
                            "  " +
                              _vm._s(_vm.errors.first("group.property")) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "shield"] },
                          }),
                          _vm._v(
                            "  " + _vm._s(_vm.$t("task.group.default")) + " "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "2" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "11px",
                                "margin-bottom": "16px",
                              },
                              attrs: { id: "default_taskgroup_chkbox" },
                            },
                            [
                              _c("b-form-checkbox", {
                                staticClass: "checkbox-width",
                                attrs: {
                                  switch: "",
                                  size: "lg",
                                  name: "enabled",
                                  disabled: _vm.readOnly || _vm.isDefault,
                                },
                                model: {
                                  value: _vm.group.isDefault,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.group, "isDefault", $$v)
                                  },
                                  expression: "group.isDefault",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "default_taskgroup_chkbox",
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("task.group.default_tooltip")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("ProfileShareEdit", {
            attrs: {
              readOnly: _vm.readOnly,
              sharedVisibility: _vm.group.sharedVisibility,
              sharingMembers: _vm.group.sharingMembers,
              editors: _vm.group.editors,
              propReady: _vm.group.propReady,
              validationErrors: _vm.errors,
            },
            on: {
              "update:sharedVisibility": function ($event) {
                return _vm.$set(_vm.group, "sharedVisibility", $event)
              },
              "update:shared-visibility": function ($event) {
                return _vm.$set(_vm.group, "sharedVisibility", $event)
              },
              "update:sharingMembers": function ($event) {
                return _vm.$set(_vm.group, "sharingMembers", $event)
              },
              "update:sharing-members": function ($event) {
                return _vm.$set(_vm.group, "sharingMembers", $event)
              },
              "update:editors": function ($event) {
                return _vm.$set(_vm.group, "editors", $event)
              },
              "update:propReady": function ($event) {
                return _vm.$set(_vm.group, "propReady", $event)
              },
              "update:prop-ready": function ($event) {
                return _vm.$set(_vm.group, "propReady", $event)
              },
            },
          }),
          _c("label", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.$t("task.group.field.tasks"))),
          ]),
          _vm.readOnly != true
            ? _c(
                "button",
                {
                  staticClass: "btn-action",
                  attrs: { id: "BTN_ADD_TASK" },
                  on: {
                    click: function ($event) {
                      return _vm.openTaskNameModal(true)
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } })],
                1
              )
            : _vm._e(),
          _c(
            "b-popover",
            {
              attrs: {
                target: "BTN_ADD_TASK",
                triggers: "hover",
                placement: "top",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("task_compact.button.add_task")) + " ")]
          ),
          _vm.readOnly != true &&
          _vm.dynamicTasks != null &&
          _vm.dynamicTasks.length > 0
            ? [
                _c(
                  "button",
                  {
                    staticClass: "btn-action",
                    attrs: { id: "BTN_ADD_DYNAMIC_TASK" },
                    on: {
                      click: function ($event) {
                        return _vm.addDynamicTasks(true)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "arrow-down-to-bracket"] },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      target: "BTN_ADD_DYNAMIC_TASK",
                      triggers: "hover",
                      placement: "top",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("task_compact.button.add_dynamic_tasks")
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
          _c("BadgeGroup", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("Badge", {
                      key: index,
                      attrs: {
                        text: item.name,
                        variant: "primary",
                        readOnly: _vm.readOnly,
                        pillable: !!item.pillable,
                      },
                      on: {
                        badgeRemove: function ($event) {
                          return _vm.columnBadgeRemove(index)
                        },
                        badgeClick: function ($event) {
                          return _vm.columnBadgeEdit(index)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.group.children,
              callback: function ($$v) {
                _vm.$set(_vm.group, "children", $$v)
              },
              expression: "group.children",
            },
          }),
          _c(
            "b-form-invalid-feedback",
            {
              staticClass: "alert-danger form-field-alert",
              class: { "d-block": _vm.showChildrenError },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["far", "circle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.errors.first("group.children")) + " "),
            ],
            1
          ),
        ],
        2
      ),
      _c("TaskGroupTaskEdit", {
        attrs: {
          show: _vm.state.taskNameShow,
          name: _vm.taskName,
          color: _vm.taskColor,
          skills: _vm.taskSkills,
          staff: _vm.taskStaff,
          resources: _vm.taskResources,
          rebates: _vm.taskRebates,
          tags: _vm.taskTags,
          existingNames: _vm.existingTaskNames,
          isNew: _vm.taskNameEditIndex == null,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "taskNameShow", $event)
          },
          ok: _vm.taskNameOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }