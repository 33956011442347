<template>
  <div :id="modalId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="modalCancel"
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible :show="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>
      
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('task.field.name')" label-for="name" :class="{ 'mb-0': showNameError }">
            <b-input-group>
              <b-form-input id="name" type="text" :disabled="readOnly" :class="[ readOnly? 'read-only':'' ]"
                :data-vv-as="$t('task.field.name')"
                data-vv-name="group.headerName"
                data-vv-delay="500"
                v-model="group.headerName" 
                v-validate="{ required: true }"
                :state="fieldValidateUtil.stateValidate(false, veeFields, errors, 'group.headerName')"
                autofocus trim>
              </b-form-input>
            </b-input-group>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('group.headerName') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group :label="$t('project.field.description')" label-for="description">
            <b-form-textarea id="description" :disabled="readOnly" :class="[ readOnly? 'read-only':'' ]"
              :placeholder="readOnly? '' : $t('project.placeholder.description')"
              :data-vv-as="$t('project.field.description')"
              data-vv-name="group.description"
              data-vv-delay="500"
              v-model="group.description"
              :max-rows="6"
              v-validate="{ max: 10000 }"
              trim
              :rows="3"/>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDescriptionError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('group.description') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row>
        <b-col cols="12" sm="6">
          <b-form-group :label="$t('task.group.field.property')" :class="{ 'mb-0': showPropertyError }">
            <b-input-group>
              <b-form-select v-model="group.property" :disabled="readOnly" :class="[ readOnly? 'read-only':'' ]"
                  :options="propertyOpts"
                  :data-vv-as="$t('task.group.field.property')"
                  data-vv-name="group.property"
                  data-vv-delay="500"
                  v-validate="{ required: true }">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>{{ $t('task.group.select_property') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
            <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showPropertyError }">
              <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('group.property') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      
        <b-col cols="12" sm="6">
          <b-row>
            <b-col cols="12">
              <font-awesome-icon :icon="['far', 'shield']"/>&nbsp;&nbsp;{{ $t('task.group.default') }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2">
              <div id="default_taskgroup_chkbox" style="margin-top: 11px; margin-bottom: 16px;">
                <b-form-checkbox class="checkbox-width" switch v-model="group.isDefault" size="lg" name="enabled" :disabled="readOnly || isDefault"></b-form-checkbox>
              </div>
              <b-popover target="default_taskgroup_chkbox" triggers="hover" placement="top">
                {{ $t('task.group.default_tooltip') }}
              </b-popover>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <ProfileShareEdit
        :readOnly="readOnly"
        :sharedVisibility.sync="group.sharedVisibility"
        :sharingMembers.sync="group.sharingMembers"
        :editors.sync="group.editors"
        :propReady.sync="group.propReady"
        :validationErrors="errors"
      />

      <label class="mr-1">{{ $t(`task.group.field.tasks`) }}</label>
      <button id="BTN_ADD_TASK" class="btn-action" v-if="readOnly != true" @click="openTaskNameModal(true)"><font-awesome-icon :icon="['far', 'plus']"/></button>
      <b-popover target="BTN_ADD_TASK" triggers="hover" placement="top">
        {{ $t('task_compact.button.add_task') }}
      </b-popover>
      <template v-if="readOnly != true && dynamicTasks != null && dynamicTasks.length > 0">
        <button id="BTN_ADD_DYNAMIC_TASK" class="btn-action" @click="addDynamicTasks(true)"><font-awesome-icon :icon="['far', 'arrow-down-to-bracket']"/></button>
        <b-popover target="BTN_ADD_DYNAMIC_TASK" triggers="hover" placement="top">
          {{ $t('task_compact.button.add_dynamic_tasks') }}
        </b-popover>
      </template>
      <BadgeGroup v-model="group.children">
        <template v-slot:default="{ item, index }">
          <Badge @badgeRemove="columnBadgeRemove(index)" @badgeClick="columnBadgeEdit(index)"
            :text="item.name"
            variant="primary" 
            :readOnly="readOnly"
            :pillable="!!item.pillable" :key="index" />
          </template>
      </BadgeGroup>
      <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showChildrenError }">
        <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('group.children') }}
      </b-form-invalid-feedback>
      
      <template v-slot:modal-footer="{ cancel }">
        <template v-if="canEdit() && !readOnly">
          <b-button size="sm" variant="success" @click="modalOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t(readOnly? 'button.close' : 'button.cancel') }}</b-button>
      </template>
    </b-modal>

    <TaskGroupTaskEdit :show.sync="state.taskNameShow" 
      :name="taskName" 
      :color="taskColor" 
      :skills="taskSkills"
      :staff="taskStaff"
      :resources="taskResources"
      :rebates="taskRebates"
      :tags="taskTags"
      :existingNames="existingTaskNames" 
      @ok="taskNameOk" 
      :isNew="taskNameEditIndex == null"
    />

  </div>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

import { objectClone } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { taskGroupProfileService } from '@/services';

export default {
  name: 'TaskGroupModal',
  components: {
    BadgeGroup: () => import('@/components/BadgeGroup/BadgeGroup'),
    Badge: () => import('@/components/BadgeGroup/components/Badge'),
    ProfileShareEdit: () => import('@/components/Profile/ProfileShareEdit'),
    TaskGroupTaskEdit: () => import('@/components/TaskGroup/TaskEdit'),
  },
  props: {
    userId:      { type: String, required: true },
    companyId:   { type: String, required: true },
    uuId:        { type: String, default: null },
    headerName: { type: String, default: null },
    description: { type: String, default: null },
    property:    { type: String, default: null },
    propertyOptions: { type: Array, default: null },
    children:    { type: Array, default: () => [] },
    dynamicTasks: { type: Array, default: () => [] },
    sharedVisibility: { type: String, default: 'public' },
    sharingMembers: { type: Array, default: () => [] },
    editors:     { type: Array, default: () => [] },
    readOnly:    { type: Boolean, default: false },
    isDefault:   { type: Boolean, default: false },
    show:        { type: Boolean, required: true },
    
  },
  data() {
    return {
      permissionName: 'TASK',
      modelInfo: null,
      alertMsg: null,
      state: {
        editable: false,
        isSubmitting: false,
        modalShow: false,
        taskNameShow: false,
      },
      group: {
        uuId: null,
        headerName: null,
        description: null,
        property: null,
        children: [
          //Sample:
          //'Layout',
        ],
        sharedVisibility: 'public',
        sharingMembers: [],
        editors: [],
        isDefault: false,
        propReady: false //Used to signal ProfileShareEdit component to start processing the provided props.
      },
      taskName: null,
      taskColor: null,
      taskSkills: [],
      taskStaff: [],
      taskResources: [],
      taskRebates: [],
      taskTags: [],
      taskNameEditIndex: null,
      existingTaskNames: [],
    }
  },
  created() {
    this.version = 1;
    this.fieldValidateUtil = fieldValidateUtil;
    this.originGroup = null;
    this.propertyOpts = [];
  },
  mounted() {
    this.initModal();
    this.state.modalShow = this.show;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
    this.originGroup = null;
    this.propertyOpts = null;
  },
  computed: {
    modalId() {
      const suffix = this.uuId != null? this.uuId : 'NEW'
      return `TASK_COLUMN_GROUP_${suffix}`;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'group.headerName');
    },
    showPropertyError() {
      return fieldValidateUtil.hasError(this.errors, 'group.property');
    },
    showDescriptionError() {
      return fieldValidateUtil.hasError(this.errors, 'group.description');
    },
    showChildrenError() {
      return fieldValidateUtil.hasError(this.errors, 'group.children');
    },    
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.group.uuId != null? this.$t('task.group.title_detail') : this.$t('task.group.title_new');
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.initModal();
        this.state.modalShow = newValue;
      }
    }
  },
  methods: {
    initModal() {
      this.$validator.resume();
      this.alertMsg = null;
      
      this.group.headerName = this.headerName != null? this.headerName : null;
      this.group.uuId = this.uuId != null? this.uuId : null;
      this.group.description = this.description != null? this.description : null;
      this.setArray(this.propertyOptions, this, 'propertyOpts');
      this.group.property = this.property != null? this.property : null;
      this.setArray(this.children, this.group, 'children');
      this.group.sharedVisibility = this.sharedVisibility;
      this.setArray(this.sharingMembers, this.group, 'sharingMembers');
      this.setArray(this.editors, this.group, 'editors');
      this.group.isDefault = this.isDefault == true;
      this.originGroup = objectClone(this.group);
      if (this.group.uuId == null) {
        if (!this.group.editors.includes(this.userId)) {
          this.group.editors.push(this.userId);
        }
        if (!this.group.sharingMembers.includes(this.userId)) {
          this.group.sharingMembers.includes(this.userId);
        }
      }
      
      //Send signal to ProfileShareEdit component to start processing data.
      setTimeout(() => {
        this.group.propReady = true; 
      }, 300)
    },
    setArray(srcArray, target, property) {
      if (srcArray != null && Array.isArray(srcArray)) {
        target[property] = objectClone(srcArray);
      } else {
        target[property] = [];
      }
    },
    modalOk() {
      this.errors.clear();
      if (this.group.property == null) {
        this.errors.add({
          field: 'group.property',
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('task.group.field.property')])
        });
      }
      
      
      if (this.group.sharedVisibility != 'public' && this.group.sharingMembers.length == 0) {
        this.errors.add({
          field: 'sharingMemberList',
          msg: this.$i18n.t('task.group.error.sharing_empty')
        });
      }


      if (this.group.editors.length == 0) {
        this.errors.add({
          field: 'editorList',
          msg: this.$i18n.t('task.group.error.editing_empty')
        });
      }
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.alertMsg = null;
          this.handleSave();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
      
    },
    modalCancel() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    async handleSave() {
      const data = objectClone(this.group);
      const hasDefaultChanged = data.isDefault != this.originGroup.isDefault;

      //Remove unrelated properties
      delete data.propReady;
      delete data.isDefault;

      let isNew = false;
      let hasChanged = false;
      if (this.group.uuId == null) {
        isNew = true;
        hasChanged = true;
        data.version = this.version;
        
        if (data.sharingMembers.find(i => i == this.userId) == null) {
          data.sharingMembers.push(this.userId);
        }
        
        if (data.editors.find(i => i == this.userId) == null) {
          data.editors.push(this.userId);
        }
      } else {
        if (!hasChanged && this.originGroup.headerName != data.headerName) {
          hasChanged = true;
        }
        if (!hasChanged && this.originGroup.description != data.description) {
          hasChanged = true;
        }
        if (!hasChanged && this.originGroup.property != data.property) {
          hasChanged = true;
        }
        if (!hasChanged && this.originGroup.children.length != data.children.length) {
          hasChanged = true;
        }
        if (!hasChanged) {
          for (let i = 0, len = data.children.length; i < len; i++) {
            if (this.originGroup.children[i].name != data.children[i].name 
                || this.originGroup.children[i].color != data.children[i].color
                || (this.originGroup.children[i].skills != null && data.children[i].skills == null)
                || (this.originGroup.children[i].skills == null && data.children[i].skills != null)
                || (this.originGroup.children[i].staff != null && data.children[i].staff == null)
                || (this.originGroup.children[i].staff == null && data.children[i].staff != null)
                || (this.originGroup.children[i].resources != null && data.children[i].resources == null)
                || (this.originGroup.children[i].resources == null && data.children[i].resources != null)
                || (this.originGroup.children[i].rebates != null && data.children[i].rebates == null)
                || (this.originGroup.children[i].rebates == null && data.children[i].rebates != null)
                || (this.originGroup.children[i].tags != null && data.children[i].tags == null)
                || (this.originGroup.children[i].tags == null && data.children[i].tags != null)) {
              hasChanged = true;
              break;
            }

            //skills
            if (this.originGroup.children[i].skills != null) {
              if (this.originGroup.children[i].skills.length != data.children[i].skills.length) {
                hasChanged = true;
                break;
              }
              let count = this.originGroup.children[i].skills.length;
              for (const item of this.originGroup.children[i].skills) {
                const found = data.children[i].skills.find(j => j.uuId == item.uuId);
                if (found == null || item.name != found.name || item.level != found.level) {
                  hasChanged = true;
                  break;
                }
                count--;
              }
              if (hasChanged || count != 0) {
                break;
              }
            }

            //staff
            if (this.originGroup.children[i].staff != null) {
              if (this.originGroup.children[i].staff.length != data.children[i].staff.length) {
                hasChanged = true;
                break;
              }
              let count = this.originGroup.children[i].staff.length;
              for (const item of this.originGroup.children[i].staff) {
                const found = data.children[i].staff.find(j => j.uuId == item.uuId);
                if (found == null || item.name != found.name || item.utilization != found.utilization) {
                  hasChanged = true;
                  break;
                }
                count--;
              }
              if (hasChanged || count != 0) {
                break;
              }
            }

            //resources
            if (this.originGroup.children[i].resources != null) {
              if (this.originGroup.children[i].resources.length != data.children[i].resources.length) {
                hasChanged = true;
                break;
              }
              let count = this.originGroup.children[i].resources.length;
              for (const item of this.originGroup.children[i].resources) {
                const found = data.children[i].resources.find(j => j.uuId == item.uuId);
                if (found == null 
                    || item.name != found.name 
                    || item.quantity != found.quantity 
                    || item.utilization != found.utilization) {
                  hasChanged = true;
                  break;
                }
                count--;
              }
              if (hasChanged || count != 0) {
                break;
              }
            }

            //rebates
            if (this.originGroup.children[i].rebates != null) {
              if (this.originGroup.children[i].rebates.length != data.children[i].rebates.length) {
                hasChanged = true;
                break;
              }
              let count = this.originGroup.children[i].rebates.length;
              for (const item of this.originGroup.children[i].rebates) {
                const found = data.children[i].rebates.find(j => j.uuId == item.uuId);
                if (found == null || item.name != found.name || item.rebate != found.rebate) {
                  hasChanged = true;
                  break;
                }
                count--;
              }
              if (hasChanged || count != 0) {
                break;
              }
            }

            //tags
            if (this.originGroup.children[i].tags != null) {
              if (this.originGroup.children[i].tags.length != data.children[i].tags.length) {
                hasChanged = true;
                break;
              }
              let count = this.originGroup.children[i].tags.length;
              for (const item of this.originGroup.children[i].tags) {
                const found = data.children[i].tags.find(j => j.uuId == item.uuId);
                if (found == null || item.name != found.name) {
                  hasChanged = true;
                  break;
                }
                count--;
              }
              if (hasChanged || count != 0) {
                break;
              }
            }
          }
        }
        if (!hasChanged && this.originGroup.sharedVisibility != data.sharedVisibility) {
          hasChanged = true;
        }
        if (!hasChanged) {
          if (data.sharingMembers.length != this.originGroup.sharingMembers.length) {
            hasChanged = true;
          } else {
            for (let i = 0, len = data.sharingMembers.length; i < len; i++) {
              if (this.originGroup.sharingMembers[i] != data.sharingMembers[i]) {
                hasChanged = true;
                break;
              }
            }
          }
        }
        if (!hasChanged) {
          if (data.editors.length != this.originGroup.editors.length) {
            hasChanged = true;
          } else {
            for (let i = 0, len = data.editors.length; i < len; i++) {
              if (this.originGroup.editors[i] != data.editors[i]) {
                hasChanged = true;
                break;
              }
            }
          }
        }
      }

      //Call profile service to create/update taskgroup profile
      if (hasChanged) {
        data.lastModified = moment.utc().valueOf();
        let hasError = false;

        if (data.uuId == null) {
          hasError = await taskGroupProfileService.create([ { ...data }], this.userId).then((response) => {  
            const rData = response.data[response.data.jobCase][0];
            data.uuId = rData.uuId;
            return false;
          })
          .catch(() => {
            this.alertMsg = this.$t('task.group.error.failed_to_create');
            return true;
          });
        } else {
          hasError = await taskGroupProfileService.update([ { ...data }], this.userId).then((/** response */) => {  
            return false;
          })
          .catch(() => {
            this.alertMsg = this.$t('task.group.error.failed_to_update');
            return true;
          });
        }
        if (hasError) {
          return;
        }

        await this.updateSharedVisibility(data.sharedVisibility, data.uuId);
      }
      
      //Assume the change is always from false to true. Reason: When original value is true, the UI is disabled to prevent change.
      if (hasDefaultChanged) {
        await taskGroupProfileService.setting_setDefaultTaskGroupId(this.userId, data.uuId, { createIfNone: true });
        if (!hasChanged) {
          this.$emit('defaultChanged', data.uuId);
        }
      }

      if (hasChanged) {
        this.$emit('ok', { payload: data, isNew, hasDefaultChanged });
      } 

      
      this.$emit('update:show',false);
    },
    async updateSharedVisibility(value, profileUuId) {
      
      const requests = [];
      const orgSharingMembers = this.originGroup.sharingMembers;
      const hasVisibilityChanged = this.originGroup.sharedVisibility != value;

      const dataSharingMembers = this.group.sharingMembers;

       if (hasVisibilityChanged) {
        if (value === 'private') {
          requests.push(taskGroupProfileService.unsharePublic(profileUuId, this.companyId));
        } else {
          requests.push(taskGroupProfileService.sharePublic(profileUuId, this.companyId));
        }
      }

      for (const userId of dataSharingMembers) {
        const foundIndex = orgSharingMembers.findIndex(i => i == userId);
        if (foundIndex > -1) {
          orgSharingMembers.splice(foundIndex, 1);
          continue;
        }
        requests.push(taskGroupProfileService.share(profileUuId, userId));
      }

      for (const userId of orgSharingMembers) {
        requests.push(taskGroupProfileService.unshare(profileUuId, userId));
      }

      if (requests.length > 0) {
        await Promise.allSettled(requests).then((/**results**/) => {
          // for (const [i, result] of results.entries()) {
          //   if (result.status != 'fulfilled') {
          //     const response = result.reason;
          //     if(response) {
          //       let clue = null;
          //       if (response.data && response.data.jobClue && response.data.jobClue.clue) {
          //         clue = response.data.jobClue.clue;
          //       }
          //       if (clue = null || clue != 'Already_have_edge') {
          //         console.error(e); //eslint-disable-line
          //       }
          //     }
          //   }
          // }
        })
      }
    },
    scrollToTop() {
      document.querySelector(`#${this.modalId}`).scrollIntoView();
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    taskNameOk(payload) {
      const newName = payload.name;
      const newColor = payload.color;
      const newSkills = payload.skills;
      const newStaff = payload.staff;
      const newResources = payload.resources;
      const newRebates = payload.rebates;
      const newTags = payload.tags;
      this.state.taskNameShow = false;
      if (this.taskNameEditIndex != null) {
        this.group.children.splice(this.taskNameEditIndex, 1, { 
          name: newName
          , color: newColor
          , skills: newSkills
          , staff: newStaff
          , resources: newResources
          , rebates: newRebates
          , tags: newTags
        });
        this.taskNameEditIndex = null;
      } else {
        this.group.children.push({ 
          name: newName
          , color: newColor 
          , skills: newSkills
          , staff: newStaff
          , resources: newResources
          , rebates: newRebates
          , tags: newTags
        });
      }
      this.errors.remove('group.children');
    },
    columnBadgeEdit: function(index) {
      this.taskNameEditIndex = index;
      this.taskName = this.group.children[index].name;
      this.taskColor = this.group.children[index].color;
      if (Array.isArray(this.group.children[index].skills)) {
        this.taskSkills = this.group.children[index].skills;
      }
      if (Array.isArray(this.group.children[index].staff)) {
        this.taskStaff = this.group.children[index].staff;
      }
      if (Array.isArray(this.group.children[index].resources)) {
        this.taskResources = this.group.children[index].resources;
      }
      if (Array.isArray(this.group.children[index].rebates)) {
        this.taskRebates = this.group.children[index].rebates;
      }
      if (Array.isArray(this.group.children[index].tags)) {
        this.taskTags = this.group.children[index].tags;
      }
      this.existingTaskNames.splice(0, this.existingTaskNames.length, ...this.group.children.filter(i => i.name != this.taskName).map(i => i.name));
      this.state.taskNameShow = true;
    },
    columnBadgeRemove: function(index) {
      this.group.children.splice(index,1);
    },
    openTaskNameModal(isNew=false) {
      if (isNew) {
        this.taskNameEditIndex = null;
        this.taskName = null;
        this.taskColor = null;
        this.taskSkills = [];
        this.taskStaff = [];
        this.taskResources = [];
        this.taskRebates = [];
        this.taskTags = [];
      }
      this.existingTaskNames.splice(0, this.existingTaskNames.length, ...this.group.children.map(i => i.name));
      this.state.taskNameShow = true;
    },
    addDynamicTasks() {
      if (this.dynamicTasks != null && this.dynamicTasks.length > 0) {
        const children = this.group.children;
        const dTasks =  Array.from(new Set(this.dynamicTasks)).map(i => ({ name: i.name }));
        for (const dTask of dTasks) {
          if (children.findIndex(i => i.name === dTask.name) > -1) {
            continue;
          }
          children.push(objectClone(dTask));
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.read-only {
  background-color: var(--task-group-modal-field-bg) !important;
  color: var(--task-group-modal-field-color) !important;
  opacity: 1 !important;
}

</style>
